.light-theme {
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --main: #0261ad;
  --primary: #0261ad;
  --primary-rgb: 2, 97, 173;
  --primary-darken-5: #025394;
  --primary-darken-10: #01457b;
  --success: #038153;
  --success-lighten: #26a275;
  --success-darken: #024f33;
  --background: #424242;
  --dark-bacground: #212121;
  --warn: #ffc107;
  --error: #ee3442;
  --error-darken: #dd1222;
  --blue: #42c0fb;
  --pink: #de5285;
  --gray-light-1: #fafafa;
  --gray-light-2: #f2f2f2;
  --gray-light-3: #dadada;
  --gray-dark-1: #888888;
  --gray-dark-2: #909090;
  --gray-dark-3: #777777;
  --blue-gray: rgb(104, 115, 125);
  --table-font-color: rgba(0, 0, 0, 0.87);
  --text-on-primary: #ffffff;
  --title-color: #2f3941;
  --table-buttons-background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 12%,
    rgba(255, 255, 255, 0.8) 15%,
    white 100%
  );
}

.dark-theme {
  --white: #333435;
  --white-rgb: 0, 0, 0;
  --black: #fff;
  --black-rgb: 255, 255, 255;
  --main: #0261ad;
  --primary: #1e8ae1;
  --primary-rgb: 2, 97, 173;
  --primary-darken-5: #025394;
  --primary-darken-10: #01457b;
  --success: #038153;
  --success-lighten: #26a275;
  --success-darken: #024f33;
  --background: #424242;
  --dark-bacground: #212121;
  --warn: #ffc107;
  --error: #ee3442;
  --error-darken: #dd1222;
  --blue: #42c0fb;
  --pink: #de5285;
  --gray-light-1: #1b1d1e;
  --gray-light-2: #1c1c1c;
  --gray-light-3: #181818;
  --gray-dark-1: #d0d0d0;
  --gray-dark-2: #c7c7c7;
  --gray-dark-3: #b7b6b6;
  --blue-gray: rgb(192, 213, 229);
  --table-font-color: #fff;
  --text-on-primary: #ffffff;
  --title-color: #e0e0e0;
  --table-buttons-background: linear-gradient(
    90deg,
    rgba(51, 52, 53, 0) 0%,
    rgba(51, 52, 53, 0.5) 12%,
    rgba(51, 52, 53, 0.9) 15%,
    #333435 100%
  );
}
