body {
  --xsMax: 575px;
  --smMin: 576px;
  --smMax: 767px;
  --mdMin: 768px;
  --mdMax: 991px;
  --lgMin: 992px;
  --lgMax: 1199px;
  --xlMin: 1200px;
}
$xsMax: 575px;
$smMin: 576px;
$smMax: 767px;
$mdMin: 768px;
$mdMax: 959px;
$lgMin: 960px;
$lgMax: 1199px;
$xlMin: 1200px;

// @forward 'self';
